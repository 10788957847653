<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="card-body">
        <form>
          <h5>
            Base product details
            <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
          </h5>

          <hr />
          <b-row>
            <b-col lg="6" md="6" sm="6">
              <inline-input
                :id="controls.product_name.id"
                :value="data.product_name"
                :label="controls.product_name.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="6" sm="6">
              <inline-select
                :id="controls.classification.id"
                :value="data.classification"
                :label="controls.classification.label"
                :readonly="controls.classification.readonly"
                :options="controls.classification.options"
                :allow-empty="false"
                :mode="mode"
                @changed="updateSelect"
                :required="true"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="6" sm="6">
              <inline-input
                :id="controls.created.id"
                :value="data.created"
                :label="controls.created.label"
                :readonly="true"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <hr />
        </form>

        <hr />
        <form-submission-actions
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          :buttons-visibility="{
            previous: $customTable.getPrevKey(dataset, id),
            next: $customTable.getNextKey(dataset, id)
          }"
          @previous-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getPrevKey(dataset, id)
              }
            })
          "
          @next-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getNextKey(dataset, id)
              }
            })
          "
          @save="
            save('tabular').then(response =>
              response ? $router.push({ name: 'Base products' }) : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Base product submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Base product submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import FormSubmissionActions from "@/components/FormSubmissionActions";

import InlineInput from "@/components/InlineInput";
import InlineSelect from "@/components/InlineSelectMultiple";

import { mapState, mapActions } from "vuex";

export default {
  name: "BaseProductSubmissionForm",
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    action: {
      type: String,
      default: ""
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    FormSubmissionActions
  },
  data: function() {
    return {
      dataset: {
        name: "production_base_products"
      },
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        buttons: {
          edit: {
            id: "button:details_edit"
          },
          save: {
            id: "button:details_save"
          }
        },
        product_name: {
          id: "input:product_name",
          label: "Product Name",
          changed: false,
          readonly: false
        },
        created: {
          id: "input:created",
          label: "Created",
          changed: false,
          readonly: false
        },

        classification: {
          id: "select:classification",
          label: "Classification",
          options: [],
          changed: false,
          readonly: false
        }
      },
      data: {
        id: "",
        product_name: "",
        created: "",
        classification: {
          id: "",
          label: ""
        }
      },
      datasetKeys: []
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted: function() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    initialize: async function() {
      if (this.action === "create") {
        this.mode = this.$constants.FORM_MODE.CREATE;
      }
      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      this.data.id = this.id;

      this.fetchDropdowns();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        await this.fetchData(this.id);
      }
    },

    fetchDropdowns() {
      this.$api.get("dictionaries/product-classifications").then(response => {
        this.controls.classification.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
      });
    },
    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let method = "";

      let url = "base-products";

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = "put";
        url = "base-products";
      }
      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        method = "put";
        url = `base-products/${this.data.id}`;
      }

      this.isLoading = true;

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastError(error.message);

          return Promise.resolve(false);
        });
    },
    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode);
    },
    updateField(field, value, mode) {
      this.$form.updateField("base-products", this, field, value, mode);
    },
    updateSelect(id, value) {
      this.$form.updateField("base-products", this, id, value, this.mode);
    },

    fetchData: async function(id) {
      let self = this;

      this.isLoading = true;
      return this.$api
        .get(`base-products/${id}`)
        .then(response => {
          this.isLoading = false;

          if (self.$_.isEmpty(response)) return;

          self.data.id = self.id;

          self.data.product_name = response["Product Name"];
          self.data.created = response["Created"];

          self.data.classification = {
            id: response["classification_id"],
            label: response["Classification"]
          };
        })
        .catch(error => {
          this.isLoading = false;

          console.log(error);
        });
    }
  },
  watch: {}
};
</script>

<style></style>
